/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable unicorn/prefer-module */

const AspectRatioPlugin = require("@tailwindcss/aspect-ratio");
const TypographyPlugin = require("@tailwindcss/typography");
const Color = require("color");

const resolveConfig = require("tailwindcss/resolveConfig");
const defaultTheme = require("tailwindcss/defaultTheme");
const defaultConfig = resolveConfig(defaultTheme);

const CARBON_BLUE = "#6D8FAD";
const CARBON_GRAY_DARK = "#111518";
const CARBON_GRAY_LIGHT = "#a2aaad";
const CARBON_GRAY_MEDIUM = "#333f48";
const CARBON_GREEN = "#518C66";
const CARBON_ORANGE = "#A9431E";
const CARBON_YELLOW = "#FFBA63";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const toTailwindRange = (sourceColor) => {
  const color500 = new Color(sourceColor);
  const color100 = color500.lighten(0.5).saturate(0.5);
  const color300 = color500.mix(color100);
  const color200 = color300.mix(color100);
  const color400 = color500.mix(color300);
  const color900 = color500.darken(0.5).saturate(0.5);
  const color700 = color500.mix(color900);
  const color600 = color500.mix(color700);
  const color800 = color900.mix(color700);
  return {
    50: color100.lighten(0.5).hex(),
    100: color100.hex(),
    200: color200.hex(),
    300: color300.hex(),
    400: color400.hex(),
    500: color500.hex(),
    600: color600.hex(),
    700: color700.hex(),
    800: color800.hex(),
    900: color900.hex(),
    950: color900.darken(0.5).hex(),
  };
};

/** @type {import('tailwindcss').Config} */
module.exports = {
  corePlugins: {
    preflight: false,
  },
  darkMode: "media",
  important: true,
  content: ["../../portal/ui/src/**/*.{html,ts,tsx}"],
  separator: ":",
  theme: {
    extend: {
      animation: {
        "reverse-spin": "reverse-spin 1s linear infinite",
      },
      keyframes: {
        "reverse-spin": {
          from: {
            transform: "rotate(360deg)",
          },
        },
      },
      borderRadius: {
        xs: "0.05rem",
      },
      leading: {
        inherit: "inherit",
      },
      colors: {
        carbon: {
          orange: CARBON_ORANGE,
          yellow: CARBON_YELLOW,
          gray: {
            dark: CARBON_GRAY_DARK,
            medium: CARBON_GRAY_MEDIUM,
            light: CARBON_GRAY_LIGHT,
          },
          green: CARBON_GREEN,
          blue: CARBON_BLUE,
          map: {
            draw: {
              fill: "#95cced",
              border: "#1da1f2",
              vertex: "#1da1f2",
              text: "white",
              textHalo: "black",
            },
            farms: {
              field: "#98ed95",
              obstacle: "#a34315",
              headland: "#ecf16e",
              default: "#bbbbbb",
              focused: "#95cced",
            },
          },
        },
        darken: {
          100: "rgba(0, 0, 0, 0.1)",
          200: "rgba(0, 0, 0, 0.2)",
          300: "rgba(0, 0, 0, 0.3)",
          400: "rgba(0, 0, 0, 0.4)",
          500: "rgba(0, 0, 0, 0.5)",
          600: "rgba(0, 0, 0, 0.6)",
          700: "rgba(0, 0, 0, 0.7)",
          800: "rgba(0, 0, 0, 0.8)",
          900: "rgba(0, 0, 0, 0.9)",
        },
        lighten: {
          100: "rgba(255, 255, 255, 0.1)",
          200: "rgba(255, 255, 255, 0.2)",
          300: "rgba(255, 255, 255, 0.3)",
          400: "rgba(255, 255, 255, 0.4)",
          500: "rgba(255, 255, 255, 0.5)",
          600: "rgba(255, 255, 255, 0.6)",
          700: "rgba(255, 255, 255, 0.7)",
          800: "rgba(255, 255, 255, 0.8)",
          900: "rgba(255, 255, 255, 0.9)",
        },
        blue: toTailwindRange(CARBON_BLUE),
        orange: toTailwindRange(CARBON_ORANGE),
        yellow: toTailwindRange(CARBON_YELLOW),
        green: toTailwindRange(CARBON_GREEN),
        // for when you want colors that are a bit closer to consensus reality
        "tailwind-orange": defaultConfig.theme.colors.orange,
        "tailwind-yellow": defaultConfig.theme.colors.yellow,
      },
      height: {
        unset: "unset",
      },
      width: {
        "1/10": "10%",
        "1/8": "calc(100%/8)",
      },
    },
    minWidth: ({ theme }) => ({
      ...theme("spacing"),
      "1/4": "25%",
      "1/2": "50%",
      full: "100%",
    }),
    maxWidth: ({ theme }) => ({
      ...theme("spacing"),
      "1/4": "25%",
      "1/3": "calc(100% / 3)",
      "1/2": "50%",
      full: "100%",
    }),
    minHeight: ({ theme }) => ({
      ...theme("spacing"),
      "1/4": "25%",
      "1/2": "50%",
      full: "100%",
    }),
    maxHeight: ({ theme }) => ({
      ...theme("spacing"),
      "1/4": "25%",
      "1/2": "50%",
      full: "100%",
    }),
    // https://www.npmjs.com/package/@tailwindcss/aspect-ratio#compatibility-with-default-aspect-ratio-utilities
    aspectRatio: {
      auto: "auto",
      square: "1 / 1",
      video: "16 / 9",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
      7: "7",
      8: "8",
      9: "9",
      10: "10",
      11: "11",
      12: "12",
      13: "13",
      14: "14",
      15: "15",
      16: "16",
    },
  },
  plugins: [AspectRatioPlugin, TypographyPlugin],
};
