// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.21.12
// source: frontend/translation.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "carbon.frontend.translation";

export interface IntegerValue {
  value: number;
}

export interface DoubleValue {
  value: number;
}

export interface StringValue {
  value: string;
}

export interface TemperatureValue {
  celcius?: number | undefined;
  fahrenheit?: number | undefined;
}

export interface PercentValue {
  percent: number;
}

export interface VoltageValue {
  volts: number;
}

export interface FrequencyValue {
  hertz: number;
}

export interface AreaValue {
  acres?: number | undefined;
  hectares?: number | undefined;
  squareFeet?: number | undefined;
  squareMeters?: number | undefined;
}

export interface DurationValue {
  milliseconds?: number | undefined;
  seconds?: number | undefined;
  minutes?: number | undefined;
  hours?: number | undefined;
}

export interface DistanceValue {
  millimeters?: number | undefined;
  meters?: number | undefined;
  kilometers?: number | undefined;
  inches?: number | undefined;
  feet?: number | undefined;
  miles?: number | undefined;
  centimeters?: number | undefined;
}

export interface SpeedValue {
  kilometersPerHour?: number | undefined;
  milesPerHour?: number | undefined;
}

export interface TranslationParameter {
  name: string;
  intValue?: IntegerValue | undefined;
  doubleValue?: DoubleValue | undefined;
  stringValue?: StringValue | undefined;
  temperatureValue?: TemperatureValue | undefined;
  percentValue?: PercentValue | undefined;
  voltageValue?: VoltageValue | undefined;
  frequencyValue?: FrequencyValue | undefined;
  areaValue?: AreaValue | undefined;
  durationValue?: DurationValue | undefined;
  distanceValue?: DistanceValue | undefined;
  speedValue?: SpeedValue | undefined;
}

function createBaseIntegerValue(): IntegerValue {
  return { value: 0 };
}

export const IntegerValue: MessageFns<IntegerValue> = {
  encode(message: IntegerValue, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.value !== 0) {
      writer.uint32(8).int64(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): IntegerValue {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIntegerValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.value = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IntegerValue {
    return { value: isSet(object.value) ? globalThis.Number(object.value) : 0 };
  },

  toJSON(message: IntegerValue): unknown {
    const obj: any = {};
    if (message.value !== 0) {
      obj.value = Math.round(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IntegerValue>, I>>(base?: I): IntegerValue {
    return IntegerValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IntegerValue>, I>>(object: I): IntegerValue {
    const message = createBaseIntegerValue();
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseDoubleValue(): DoubleValue {
  return { value: 0 };
}

export const DoubleValue: MessageFns<DoubleValue> = {
  encode(message: DoubleValue, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.value !== 0) {
      writer.uint32(9).double(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DoubleValue {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDoubleValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.value = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DoubleValue {
    return { value: isSet(object.value) ? globalThis.Number(object.value) : 0 };
  },

  toJSON(message: DoubleValue): unknown {
    const obj: any = {};
    if (message.value !== 0) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DoubleValue>, I>>(base?: I): DoubleValue {
    return DoubleValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DoubleValue>, I>>(object: I): DoubleValue {
    const message = createBaseDoubleValue();
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseStringValue(): StringValue {
  return { value: "" };
}

export const StringValue: MessageFns<StringValue> = {
  encode(message: StringValue, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.value !== "") {
      writer.uint32(10).string(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): StringValue {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStringValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StringValue {
    return { value: isSet(object.value) ? globalThis.String(object.value) : "" };
  },

  toJSON(message: StringValue): unknown {
    const obj: any = {};
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StringValue>, I>>(base?: I): StringValue {
    return StringValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StringValue>, I>>(object: I): StringValue {
    const message = createBaseStringValue();
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseTemperatureValue(): TemperatureValue {
  return { celcius: undefined, fahrenheit: undefined };
}

export const TemperatureValue: MessageFns<TemperatureValue> = {
  encode(message: TemperatureValue, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.celcius !== undefined) {
      writer.uint32(9).double(message.celcius);
    }
    if (message.fahrenheit !== undefined) {
      writer.uint32(17).double(message.fahrenheit);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TemperatureValue {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTemperatureValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.celcius = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.fahrenheit = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TemperatureValue {
    return {
      celcius: isSet(object.celcius) ? globalThis.Number(object.celcius) : undefined,
      fahrenheit: isSet(object.fahrenheit) ? globalThis.Number(object.fahrenheit) : undefined,
    };
  },

  toJSON(message: TemperatureValue): unknown {
    const obj: any = {};
    if (message.celcius !== undefined) {
      obj.celcius = message.celcius;
    }
    if (message.fahrenheit !== undefined) {
      obj.fahrenheit = message.fahrenheit;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TemperatureValue>, I>>(base?: I): TemperatureValue {
    return TemperatureValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TemperatureValue>, I>>(object: I): TemperatureValue {
    const message = createBaseTemperatureValue();
    message.celcius = object.celcius ?? undefined;
    message.fahrenheit = object.fahrenheit ?? undefined;
    return message;
  },
};

function createBasePercentValue(): PercentValue {
  return { percent: 0 };
}

export const PercentValue: MessageFns<PercentValue> = {
  encode(message: PercentValue, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.percent !== 0) {
      writer.uint32(8).uint32(message.percent);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PercentValue {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePercentValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.percent = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PercentValue {
    return { percent: isSet(object.percent) ? globalThis.Number(object.percent) : 0 };
  },

  toJSON(message: PercentValue): unknown {
    const obj: any = {};
    if (message.percent !== 0) {
      obj.percent = Math.round(message.percent);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PercentValue>, I>>(base?: I): PercentValue {
    return PercentValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PercentValue>, I>>(object: I): PercentValue {
    const message = createBasePercentValue();
    message.percent = object.percent ?? 0;
    return message;
  },
};

function createBaseVoltageValue(): VoltageValue {
  return { volts: 0 };
}

export const VoltageValue: MessageFns<VoltageValue> = {
  encode(message: VoltageValue, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.volts !== 0) {
      writer.uint32(9).double(message.volts);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): VoltageValue {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVoltageValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.volts = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VoltageValue {
    return { volts: isSet(object.volts) ? globalThis.Number(object.volts) : 0 };
  },

  toJSON(message: VoltageValue): unknown {
    const obj: any = {};
    if (message.volts !== 0) {
      obj.volts = message.volts;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VoltageValue>, I>>(base?: I): VoltageValue {
    return VoltageValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VoltageValue>, I>>(object: I): VoltageValue {
    const message = createBaseVoltageValue();
    message.volts = object.volts ?? 0;
    return message;
  },
};

function createBaseFrequencyValue(): FrequencyValue {
  return { hertz: 0 };
}

export const FrequencyValue: MessageFns<FrequencyValue> = {
  encode(message: FrequencyValue, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.hertz !== 0) {
      writer.uint32(9).double(message.hertz);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FrequencyValue {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFrequencyValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.hertz = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FrequencyValue {
    return { hertz: isSet(object.hertz) ? globalThis.Number(object.hertz) : 0 };
  },

  toJSON(message: FrequencyValue): unknown {
    const obj: any = {};
    if (message.hertz !== 0) {
      obj.hertz = message.hertz;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FrequencyValue>, I>>(base?: I): FrequencyValue {
    return FrequencyValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FrequencyValue>, I>>(object: I): FrequencyValue {
    const message = createBaseFrequencyValue();
    message.hertz = object.hertz ?? 0;
    return message;
  },
};

function createBaseAreaValue(): AreaValue {
  return { acres: undefined, hectares: undefined, squareFeet: undefined, squareMeters: undefined };
}

export const AreaValue: MessageFns<AreaValue> = {
  encode(message: AreaValue, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.acres !== undefined) {
      writer.uint32(9).double(message.acres);
    }
    if (message.hectares !== undefined) {
      writer.uint32(17).double(message.hectares);
    }
    if (message.squareFeet !== undefined) {
      writer.uint32(25).double(message.squareFeet);
    }
    if (message.squareMeters !== undefined) {
      writer.uint32(33).double(message.squareMeters);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AreaValue {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAreaValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.acres = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.hectares = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.squareFeet = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.squareMeters = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AreaValue {
    return {
      acres: isSet(object.acres) ? globalThis.Number(object.acres) : undefined,
      hectares: isSet(object.hectares) ? globalThis.Number(object.hectares) : undefined,
      squareFeet: isSet(object.squareFeet) ? globalThis.Number(object.squareFeet) : undefined,
      squareMeters: isSet(object.squareMeters) ? globalThis.Number(object.squareMeters) : undefined,
    };
  },

  toJSON(message: AreaValue): unknown {
    const obj: any = {};
    if (message.acres !== undefined) {
      obj.acres = message.acres;
    }
    if (message.hectares !== undefined) {
      obj.hectares = message.hectares;
    }
    if (message.squareFeet !== undefined) {
      obj.squareFeet = message.squareFeet;
    }
    if (message.squareMeters !== undefined) {
      obj.squareMeters = message.squareMeters;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AreaValue>, I>>(base?: I): AreaValue {
    return AreaValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AreaValue>, I>>(object: I): AreaValue {
    const message = createBaseAreaValue();
    message.acres = object.acres ?? undefined;
    message.hectares = object.hectares ?? undefined;
    message.squareFeet = object.squareFeet ?? undefined;
    message.squareMeters = object.squareMeters ?? undefined;
    return message;
  },
};

function createBaseDurationValue(): DurationValue {
  return { milliseconds: undefined, seconds: undefined, minutes: undefined, hours: undefined };
}

export const DurationValue: MessageFns<DurationValue> = {
  encode(message: DurationValue, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.milliseconds !== undefined) {
      writer.uint32(8).uint64(message.milliseconds);
    }
    if (message.seconds !== undefined) {
      writer.uint32(16).uint64(message.seconds);
    }
    if (message.minutes !== undefined) {
      writer.uint32(24).uint64(message.minutes);
    }
    if (message.hours !== undefined) {
      writer.uint32(32).uint64(message.hours);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DurationValue {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDurationValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.milliseconds = longToNumber(reader.uint64());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.seconds = longToNumber(reader.uint64());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.minutes = longToNumber(reader.uint64());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.hours = longToNumber(reader.uint64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DurationValue {
    return {
      milliseconds: isSet(object.milliseconds) ? globalThis.Number(object.milliseconds) : undefined,
      seconds: isSet(object.seconds) ? globalThis.Number(object.seconds) : undefined,
      minutes: isSet(object.minutes) ? globalThis.Number(object.minutes) : undefined,
      hours: isSet(object.hours) ? globalThis.Number(object.hours) : undefined,
    };
  },

  toJSON(message: DurationValue): unknown {
    const obj: any = {};
    if (message.milliseconds !== undefined) {
      obj.milliseconds = Math.round(message.milliseconds);
    }
    if (message.seconds !== undefined) {
      obj.seconds = Math.round(message.seconds);
    }
    if (message.minutes !== undefined) {
      obj.minutes = Math.round(message.minutes);
    }
    if (message.hours !== undefined) {
      obj.hours = Math.round(message.hours);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DurationValue>, I>>(base?: I): DurationValue {
    return DurationValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DurationValue>, I>>(object: I): DurationValue {
    const message = createBaseDurationValue();
    message.milliseconds = object.milliseconds ?? undefined;
    message.seconds = object.seconds ?? undefined;
    message.minutes = object.minutes ?? undefined;
    message.hours = object.hours ?? undefined;
    return message;
  },
};

function createBaseDistanceValue(): DistanceValue {
  return {
    millimeters: undefined,
    meters: undefined,
    kilometers: undefined,
    inches: undefined,
    feet: undefined,
    miles: undefined,
    centimeters: undefined,
  };
}

export const DistanceValue: MessageFns<DistanceValue> = {
  encode(message: DistanceValue, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.millimeters !== undefined) {
      writer.uint32(9).double(message.millimeters);
    }
    if (message.meters !== undefined) {
      writer.uint32(17).double(message.meters);
    }
    if (message.kilometers !== undefined) {
      writer.uint32(25).double(message.kilometers);
    }
    if (message.inches !== undefined) {
      writer.uint32(33).double(message.inches);
    }
    if (message.feet !== undefined) {
      writer.uint32(41).double(message.feet);
    }
    if (message.miles !== undefined) {
      writer.uint32(49).double(message.miles);
    }
    if (message.centimeters !== undefined) {
      writer.uint32(57).double(message.centimeters);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DistanceValue {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDistanceValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.millimeters = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.meters = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.kilometers = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.inches = reader.double();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.feet = reader.double();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.miles = reader.double();
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.centimeters = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DistanceValue {
    return {
      millimeters: isSet(object.millimeters) ? globalThis.Number(object.millimeters) : undefined,
      meters: isSet(object.meters) ? globalThis.Number(object.meters) : undefined,
      kilometers: isSet(object.kilometers) ? globalThis.Number(object.kilometers) : undefined,
      inches: isSet(object.inches) ? globalThis.Number(object.inches) : undefined,
      feet: isSet(object.feet) ? globalThis.Number(object.feet) : undefined,
      miles: isSet(object.miles) ? globalThis.Number(object.miles) : undefined,
      centimeters: isSet(object.centimeters) ? globalThis.Number(object.centimeters) : undefined,
    };
  },

  toJSON(message: DistanceValue): unknown {
    const obj: any = {};
    if (message.millimeters !== undefined) {
      obj.millimeters = message.millimeters;
    }
    if (message.meters !== undefined) {
      obj.meters = message.meters;
    }
    if (message.kilometers !== undefined) {
      obj.kilometers = message.kilometers;
    }
    if (message.inches !== undefined) {
      obj.inches = message.inches;
    }
    if (message.feet !== undefined) {
      obj.feet = message.feet;
    }
    if (message.miles !== undefined) {
      obj.miles = message.miles;
    }
    if (message.centimeters !== undefined) {
      obj.centimeters = message.centimeters;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DistanceValue>, I>>(base?: I): DistanceValue {
    return DistanceValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DistanceValue>, I>>(object: I): DistanceValue {
    const message = createBaseDistanceValue();
    message.millimeters = object.millimeters ?? undefined;
    message.meters = object.meters ?? undefined;
    message.kilometers = object.kilometers ?? undefined;
    message.inches = object.inches ?? undefined;
    message.feet = object.feet ?? undefined;
    message.miles = object.miles ?? undefined;
    message.centimeters = object.centimeters ?? undefined;
    return message;
  },
};

function createBaseSpeedValue(): SpeedValue {
  return { kilometersPerHour: undefined, milesPerHour: undefined };
}

export const SpeedValue: MessageFns<SpeedValue> = {
  encode(message: SpeedValue, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.kilometersPerHour !== undefined) {
      writer.uint32(9).double(message.kilometersPerHour);
    }
    if (message.milesPerHour !== undefined) {
      writer.uint32(17).double(message.milesPerHour);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SpeedValue {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpeedValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.kilometersPerHour = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.milesPerHour = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpeedValue {
    return {
      kilometersPerHour: isSet(object.kilometersPerHour) ? globalThis.Number(object.kilometersPerHour) : undefined,
      milesPerHour: isSet(object.milesPerHour) ? globalThis.Number(object.milesPerHour) : undefined,
    };
  },

  toJSON(message: SpeedValue): unknown {
    const obj: any = {};
    if (message.kilometersPerHour !== undefined) {
      obj.kilometersPerHour = message.kilometersPerHour;
    }
    if (message.milesPerHour !== undefined) {
      obj.milesPerHour = message.milesPerHour;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SpeedValue>, I>>(base?: I): SpeedValue {
    return SpeedValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SpeedValue>, I>>(object: I): SpeedValue {
    const message = createBaseSpeedValue();
    message.kilometersPerHour = object.kilometersPerHour ?? undefined;
    message.milesPerHour = object.milesPerHour ?? undefined;
    return message;
  },
};

function createBaseTranslationParameter(): TranslationParameter {
  return {
    name: "",
    intValue: undefined,
    doubleValue: undefined,
    stringValue: undefined,
    temperatureValue: undefined,
    percentValue: undefined,
    voltageValue: undefined,
    frequencyValue: undefined,
    areaValue: undefined,
    durationValue: undefined,
    distanceValue: undefined,
    speedValue: undefined,
  };
}

export const TranslationParameter: MessageFns<TranslationParameter> = {
  encode(message: TranslationParameter, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.intValue !== undefined) {
      IntegerValue.encode(message.intValue, writer.uint32(18).fork()).join();
    }
    if (message.doubleValue !== undefined) {
      DoubleValue.encode(message.doubleValue, writer.uint32(26).fork()).join();
    }
    if (message.stringValue !== undefined) {
      StringValue.encode(message.stringValue, writer.uint32(34).fork()).join();
    }
    if (message.temperatureValue !== undefined) {
      TemperatureValue.encode(message.temperatureValue, writer.uint32(42).fork()).join();
    }
    if (message.percentValue !== undefined) {
      PercentValue.encode(message.percentValue, writer.uint32(50).fork()).join();
    }
    if (message.voltageValue !== undefined) {
      VoltageValue.encode(message.voltageValue, writer.uint32(58).fork()).join();
    }
    if (message.frequencyValue !== undefined) {
      FrequencyValue.encode(message.frequencyValue, writer.uint32(66).fork()).join();
    }
    if (message.areaValue !== undefined) {
      AreaValue.encode(message.areaValue, writer.uint32(74).fork()).join();
    }
    if (message.durationValue !== undefined) {
      DurationValue.encode(message.durationValue, writer.uint32(82).fork()).join();
    }
    if (message.distanceValue !== undefined) {
      DistanceValue.encode(message.distanceValue, writer.uint32(90).fork()).join();
    }
    if (message.speedValue !== undefined) {
      SpeedValue.encode(message.speedValue, writer.uint32(98).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TranslationParameter {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTranslationParameter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.intValue = IntegerValue.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.doubleValue = DoubleValue.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.stringValue = StringValue.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.temperatureValue = TemperatureValue.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.percentValue = PercentValue.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.voltageValue = VoltageValue.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.frequencyValue = FrequencyValue.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.areaValue = AreaValue.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.durationValue = DurationValue.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.distanceValue = DistanceValue.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.speedValue = SpeedValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TranslationParameter {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      intValue: isSet(object.intValue) ? IntegerValue.fromJSON(object.intValue) : undefined,
      doubleValue: isSet(object.doubleValue) ? DoubleValue.fromJSON(object.doubleValue) : undefined,
      stringValue: isSet(object.stringValue) ? StringValue.fromJSON(object.stringValue) : undefined,
      temperatureValue: isSet(object.temperatureValue) ? TemperatureValue.fromJSON(object.temperatureValue) : undefined,
      percentValue: isSet(object.percentValue) ? PercentValue.fromJSON(object.percentValue) : undefined,
      voltageValue: isSet(object.voltageValue) ? VoltageValue.fromJSON(object.voltageValue) : undefined,
      frequencyValue: isSet(object.frequencyValue) ? FrequencyValue.fromJSON(object.frequencyValue) : undefined,
      areaValue: isSet(object.areaValue) ? AreaValue.fromJSON(object.areaValue) : undefined,
      durationValue: isSet(object.durationValue) ? DurationValue.fromJSON(object.durationValue) : undefined,
      distanceValue: isSet(object.distanceValue) ? DistanceValue.fromJSON(object.distanceValue) : undefined,
      speedValue: isSet(object.speedValue) ? SpeedValue.fromJSON(object.speedValue) : undefined,
    };
  },

  toJSON(message: TranslationParameter): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.intValue !== undefined) {
      obj.intValue = IntegerValue.toJSON(message.intValue);
    }
    if (message.doubleValue !== undefined) {
      obj.doubleValue = DoubleValue.toJSON(message.doubleValue);
    }
    if (message.stringValue !== undefined) {
      obj.stringValue = StringValue.toJSON(message.stringValue);
    }
    if (message.temperatureValue !== undefined) {
      obj.temperatureValue = TemperatureValue.toJSON(message.temperatureValue);
    }
    if (message.percentValue !== undefined) {
      obj.percentValue = PercentValue.toJSON(message.percentValue);
    }
    if (message.voltageValue !== undefined) {
      obj.voltageValue = VoltageValue.toJSON(message.voltageValue);
    }
    if (message.frequencyValue !== undefined) {
      obj.frequencyValue = FrequencyValue.toJSON(message.frequencyValue);
    }
    if (message.areaValue !== undefined) {
      obj.areaValue = AreaValue.toJSON(message.areaValue);
    }
    if (message.durationValue !== undefined) {
      obj.durationValue = DurationValue.toJSON(message.durationValue);
    }
    if (message.distanceValue !== undefined) {
      obj.distanceValue = DistanceValue.toJSON(message.distanceValue);
    }
    if (message.speedValue !== undefined) {
      obj.speedValue = SpeedValue.toJSON(message.speedValue);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TranslationParameter>, I>>(base?: I): TranslationParameter {
    return TranslationParameter.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TranslationParameter>, I>>(object: I): TranslationParameter {
    const message = createBaseTranslationParameter();
    message.name = object.name ?? "";
    message.intValue = (object.intValue !== undefined && object.intValue !== null)
      ? IntegerValue.fromPartial(object.intValue)
      : undefined;
    message.doubleValue = (object.doubleValue !== undefined && object.doubleValue !== null)
      ? DoubleValue.fromPartial(object.doubleValue)
      : undefined;
    message.stringValue = (object.stringValue !== undefined && object.stringValue !== null)
      ? StringValue.fromPartial(object.stringValue)
      : undefined;
    message.temperatureValue = (object.temperatureValue !== undefined && object.temperatureValue !== null)
      ? TemperatureValue.fromPartial(object.temperatureValue)
      : undefined;
    message.percentValue = (object.percentValue !== undefined && object.percentValue !== null)
      ? PercentValue.fromPartial(object.percentValue)
      : undefined;
    message.voltageValue = (object.voltageValue !== undefined && object.voltageValue !== null)
      ? VoltageValue.fromPartial(object.voltageValue)
      : undefined;
    message.frequencyValue = (object.frequencyValue !== undefined && object.frequencyValue !== null)
      ? FrequencyValue.fromPartial(object.frequencyValue)
      : undefined;
    message.areaValue = (object.areaValue !== undefined && object.areaValue !== null)
      ? AreaValue.fromPartial(object.areaValue)
      : undefined;
    message.durationValue = (object.durationValue !== undefined && object.durationValue !== null)
      ? DurationValue.fromPartial(object.durationValue)
      : undefined;
    message.distanceValue = (object.distanceValue !== undefined && object.distanceValue !== null)
      ? DistanceValue.fromPartial(object.distanceValue)
      : undefined;
    message.speedValue = (object.speedValue !== undefined && object.speedValue !== null)
      ? SpeedValue.fromPartial(object.speedValue)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
